import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, Col, Dropdown, Row, Table } from 'react-bootstrap';

//Import
import { SVGICON } from '../../constant/theme';
import MarketOverViewBlog from '../../elements/dashboard/MarketOverViewBlog';
import { ThemeContext } from '../../../context/ThemeContext';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';

export function MainComponent() {

  const cellSpacing = [5, 5];

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  return (
    <>
      <div className='container'>

        <DashboardLayoutComponent id='defaultLayout' cellSpacing={cellSpacing} allowResizing={true} columns={10}>
          <div id="one" className="e-panel" data-row="0" data-col="0" data-sizex="10" data-sizey="4">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <MarketOverViewBlog />
            </div>
          </div>
          <div id="two" className="e-panel" data-row="4" data-col="0" data-sizex="5" data-sizey="4">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <Card>
                <Card.Header>
                  <Card.Title>Market News</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>Date published</strong>
                        </th>
                        <th>
                          <strong>Ticker</strong>
                        </th>
                        <th>
                          <strong>Title</strong>
                        </th>
                        <th>
                          <strong>Description</strong>
                        </th>
                        <th>
                          <strong>Link</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Mr. Bobby</td>
                        <td>Dr. Jackson</td>
                        <td>01 August 2022</td>
                        <td>
                          <Badge bg="" className="light badge-success">
                            Successful
                          </Badge>
                        </td>
                        <td>$21.56</td>
                      </tr>
                      <tr>
                        <td>Mr. Bobby</td>
                        <td>Dr. Jackson</td>
                        <td>01 August 2022</td>
                        <td>
                          <Badge bg="" className="light badge-danger">
                            Canceled
                          </Badge>
                        </td>
                        <td>$21.56</td>
                      </tr>
                      <tr>
                        <td>Mr. Bobby</td>
                        <td>Dr. Jackson</td>
                        <td>01 August 2022</td>
                        <td>
                          <Badge bg="" className="light badge-warning">
                            Pending
                          </Badge>
                        </td>
                        <td>$21.56</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div id="three" className="e-panel" data-row="4" data-col="5" data-sizex="5" data-sizey="4">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <Card>
                <Card.Header>
                  <Card.Title>Market Events</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="width80">
                          <strong>Type</strong>
                        </th>
                        <th>
                          <strong>Time</strong>
                        </th>
                        <th>
                          <strong>Country</strong>
                        </th>
                        <th>
                          <strong>Period</strong>
                        </th>
                        <th>
                          <strong>Actual</strong>
                        </th>
                        <th>
                          <strong>Preious</strong>
                        </th>
                        <th>
                          <strong>Estimate</strong>
                        </th>
                        <th>
                          <strong>Change</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Mr. Bobby</td>
                        <td>Dr. Jackson</td>
                        <td>01 August 2022</td>
                        <td>
                          <Badge bg="" className="light badge-success">
                            Successful
                          </Badge>
                        </td>
                        <td>$21.56</td>
                        <td>$21.56</td>
                        <td>$21.56</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              className="light sharp i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>Edit</Dropdown.Item>
                              <Dropdown.Item>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                      <tr>
                        <td>Mr. Bobby</td>
                        <td>Dr. Jackson</td>
                        <td>01 August 2022</td>
                        <td>
                          <Badge bg="" className="light badge-success">
                            Successful
                          </Badge>
                        </td>
                        <td>$21.56</td>
                        <td>$21.56</td>
                        <td>$21.56</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              className="light sharp i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>Edit</Dropdown.Item>
                              <Dropdown.Item>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                      <tr>
                        <td>Mr. Bobby</td>
                        <td>Dr. Jackson</td>
                        <td>01 August 2022</td>
                        <td>
                          <Badge bg="" className="light badge-success">
                            Successful
                          </Badge>
                        </td>
                        <td>$21.56</td>
                        <td>$21.56</td>
                        <td>$21.56</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              className="light sharp i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>Edit</Dropdown.Item>
                              <Dropdown.Item>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div id="four" className="e-panel" data-row="8" data-col="0" data-sizex="10" data-sizey="2">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <Card>
                <Card.Header>
                  <Card.Title>High-Confidence Alerts</Card.Title>
                </Card.Header>
                <Card.Body></Card.Body>
              </Card>
            </div>
          </div>
          <div id="five" className="e-panel" data-row="10" data-col="0" data-sizex="10" data-sizey="2">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <Card>
                <Card.Header>
                  <Card.Title>Potential Alerts</Card.Title>
                </Card.Header>
                <Card.Body></Card.Body>
              </Card>
            </div>
          </div>
          {/* <div id="two" className="e-panel" data-row="5" data-col="1" data-sizex="5" data-sizey="3">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
            <div className="text-align">2</div>
            </div>
          </div>
           */}
          {/* <div id="six" className="e-panel" data-row="0" data-col="3" data-sizex="1" data-sizey="1">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <div className="text-align">5</div>
            </div>
          </div>
          <div id="seven" className="e-panel" data-row="1" data-col="3" data-sizex="1" data-sizey="1">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <div className="text-align">6</div>
            </div>
          </div>
          <div id="eight" className="e-panel" data-row="0" data-col="4" data-sizex="1" data-sizey="3">
            <span id="close" className="e-template-icon e-clear-icon" />
            <div className="e-panel-container">
              <div className="text-align">7</div>
            </div>
          </div> */}
        </DashboardLayoutComponent>
      </div>
      {/* <Row>
      <div className="col-xl-12">
        <div className="card market-chart">
          <div className="card-header border-0 pb-0 flex-wrap">
            <div className="mb-0">
              <h4 className="card-title">Market Overview</h4>
              <p>Lorem ipsum dolor sit amet, consectetur</p>
            </div>
            <Link to={'#'} className="btn-link text-primary get-report mb-2">
              {SVGICON.GetReportIcon}
              Get Report
            </Link>
          </div>
          <MarketOverViewBlog />
        </div>
      </div>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Market News</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>Date published</strong>
                  </th>
                  <th>
                    <strong>Ticker</strong>
                  </th>
                  <th>
                    <strong>Title</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Link</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Mr. Bobby</td>
                  <td>Dr. Jackson</td>
                  <td>01 August 2022</td>
                  <td>
                    <Badge bg="" className="light badge-success">
                      Successful
                    </Badge>
                  </td>
                  <td>$21.56</td>
                </tr>
                <tr>
                  <td>Mr. Bobby</td>
                  <td>Dr. Jackson</td>
                  <td>01 August 2022</td>
                  <td>
                    <Badge bg="" className="light badge-danger">
                      Canceled
                    </Badge>
                  </td>
                  <td>$21.56</td>
                </tr>
                <tr>
                  <td>Mr. Bobby</td>
                  <td>Dr. Jackson</td>
                  <td>01 August 2022</td>
                  <td>
                    <Badge bg="" className="light badge-warning">
                      Pending
                    </Badge>
                  </td>
                  <td>$21.56</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <Card.Header>
            <Card.Title>Market Events</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th className="width80">
                    <strong>Type</strong>
                  </th>
                  <th>
                    <strong>Time</strong>
                  </th>
                  <th>
                    <strong>Country</strong>
                  </th>
                  <th>
                    <strong>Period</strong>
                  </th>
                  <th>
                    <strong>Actual</strong>
                  </th>
                  <th>
                    <strong>Preious</strong>
                  </th>
                  <th>
                    <strong>Estimate</strong>
                  </th>
                  <th>
                    <strong>Change</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Mr. Bobby</td>
                  <td>Dr. Jackson</td>
                  <td>01 August 2022</td>
                  <td>
                    <Badge bg="" className="light badge-success">
                      Successful
                    </Badge>
                  </td>
                  <td>$21.56</td>
                  <td>$21.56</td>
                  <td>$21.56</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="light sharp i-false"
                      >
                        {svg1}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>Edit</Dropdown.Item>
                        <Dropdown.Item>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>Mr. Bobby</td>
                  <td>Dr. Jackson</td>
                  <td>01 August 2022</td>
                  <td>
                    <Badge bg="" className="light badge-success">
                      Successful
                    </Badge>
                  </td>
                  <td>$21.56</td>
                  <td>$21.56</td>
                  <td>$21.56</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="light sharp i-false"
                      >
                        {svg1}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>Edit</Dropdown.Item>
                        <Dropdown.Item>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>Mr. Bobby</td>
                  <td>Dr. Jackson</td>
                  <td>01 August 2022</td>
                  <td>
                    <Badge bg="" className="light badge-success">
                      Successful
                    </Badge>
                  </td>
                  <td>$21.56</td>
                  <td>$21.56</td>
                  <td>$21.56</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="light sharp i-false"
                      >
                        {svg1}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>Edit</Dropdown.Item>
                        <Dropdown.Item>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>High-Confidence Alerts</Card.Title>
          </Card.Header>
          <Card.Body></Card.Body>
        </Card>
      </Col>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Potential Alerts</Card.Title>
          </Card.Header>
          <Card.Body></Card.Body>
        </Card>
      </Col>
    </Row> */}
    </>
  );
}

const Home = () => {
  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: 'light', label: 'Light' });
  }, []);

  return (
    <>
      <MainComponent />
    </>
  );
};

export default Home;
